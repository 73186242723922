body {
  margin: 0;
  padding: 0;
  margin-left: 5px;
  overflow-x: hidden !important;
}

html {
  scroll-behavior: smooth;
}

@media only screen and (max-width: 1107px) {
  body {
    margin: 0;
    width: 100%;
    height: 100%;
  }
}
