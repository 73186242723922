.container {
  background: url(https://alltechappliance.com/wp-content/uploads/2019/02/Washing-Machine-being-Repaired-in-Portland-880x350.jpeg)
    no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  margin-top: 100px;
  color: white;
  z-index: 999;
  padding: 100px 0;
  margin-right: -7px;
  margin-left: -7px;
}

.container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(183, 96, 9, 0.8);
  z-index: -1;
}
